<template>
  <form
    action="#"
    class="relations-form"
    @submit.prevent="submitRelations"
    novalidate
  >
    <div class="relations-form__inputs flex justify-content-center">
      <div class="col-50 relations-form__col">
        <div>
          <custom-select-2
            label="Тип сущности на сайте"
            name="type"
            v-model="form.site_entity_type"
            :options="[
              { id: 'product', text: 'Товар' },
              { id: 'packet', text: 'Пакетное решение' },
            ]"
          />
        </div>
        <div>
          <custom-select-2
            label="Сущность на сайте"
            name="product"
            v-show="form.site_entity_type"
            v-model="form.site_entity_id"
            :options="productsOptions"
          />
        </div>
      </div>
      <div class="col-50 relations-form__col">
        <custom-select-2
          label="Тип сущности в ЛК"
          name="entity"
          v-model="form.fillable_type"
          :options="[
            { id: 'course', text: 'Курс' },
            { id: 'test', text: 'Тест' },
          ]"
        />
        <custom-select-2
          label="Сущность в ЛК"
          name="entity"
          v-show="form.fillable_type"
          v-model="form.fillable_id"
          :options="entitiesOptions"
        />
        <div class="relations-form__course-link flex justify-content-end">
          <a
            :href="courseLink"
            target="_blank"
            v-if="courseLink"
            class="btn btn-border btn-sm"
            >Смотреть курс</a
          >
        </div>
        <custom-input
          v-show="form.fillable_id"
          v-model="form.entity_name"
          name="entity_name"
          label="Название"
        />
        <custom-input
          v-show="form.fillable_id"
          v-model="form.count"
          type="number"
          name="count"
          :min="0"
          label="Количество"
        />
        <custom-input
            v-show="form.fillable_type === 'course' && form.fillable_id"
            v-model="form.access_duration"
            type="number"
            name="count"
            :min="0"
            label="Срок доступа (в днях)"
        />
      </div>
    </div>
    <div
      class="relations-form__btns mt-25 mb-10 display--flex justify-content-end"
    >
      <button type="submit" class="btn btn-accent mr-10">Добавить</button>
    </div>
  </form>
</template>

<script>
import { useStore } from "vuex";
import useErrors from "@/mixins/useErrors";
import { reactive } from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import standartError from "@/mixins/standartError";
import { notify } from "@kyvg/vue3-notification";
import useCheckRights from "@/mixins/useCheckRights";
import {useRouter} from "vue-router";

export default {
  components: { CustomSelect2, CustomInput },
  name: "relations-form",
  setup(props, context) {
    const store = useStore(),
      { setErrors, clearCustomErrors } = useErrors();

    const { getRight } = useCheckRights();
    const router = useRouter();
    const profile = computed(() => store.state.profile.profile);
    const form = reactive({
      site_entity_type: null,
      site_entity_id: null,
      fillable_type: null,
      fillable_id: null,
      count: null,
      entity_name: null,
      access_duration: 180,
    });
    const siteProducts = computed(() =>
        store.state.relations.productsList
          .filter((product) => product.type == "product")
          .map((product) => {
            return { id: product.id, text: product.name };
          })
      ),
      sitePackets = computed(() =>
        store.state.relations.productsList
          .filter((product) => product.type == "packet")
          .map((product) => {
            return { id: product.id, text: product.name };
          })
      ),
      productsOptions = computed(() =>
        form.site_entity_type == "product"
          ? siteProducts.value
          : sitePackets.value
      ),
      entitiesCourses = computed(() =>
        store.state.relations.entitiesList.courses.map((entity) => {
          return { id: entity.id, text: entity.title };
        })
      ),
      entitiesTests = computed(() =>
        Object.entries(store.state.relations.entitiesList.tests).map(
          (entity) => {
            return { id: entity[0], text: entity[1] };
          }
        )
      ),
      courseLink = computed(() =>
        form.fillable_type == "course" && form.fillable_id
          ? store.state.relations.entitiesList.courses.find(
              (entity) => entity.id == form.fillable_id
            ).url
          : ""
      ),
      entitiesOptions = computed(() =>
        form.fillable_type == "course"
          ? entitiesCourses.value
          : entitiesTests.value
      );

    onBeforeMount(() => {

      if (profile.value && profile.value.role && !getRight('relations')) {
        router.push('/');
      }

      store.dispatch("relations/getProductsList");
      store.dispatch("relations/getEntitiesList");
    });

    const submitRelations = () => {
      if (
        form.site_entity_type &&
        form.site_entity_id &&
        form.fillable_type &&
        form.fillable_id &&
        form.count
      ) {
        store
          .dispatch("relations/createRelation", form)
          .catch(standartError(setErrors, clearCustomErrors));
      } else {
        notify({
          type: "error",
          title: "Все поля должны быть заполнены",
        });
      }
    };

    watch(
      () => form.fillable_type,
      () => {
        form.fillable_id = null;
      }
    );
    watch(
      () => form.site_entity_id,
      () => {
        context.emit("changeEntity", form.site_entity_id);
      }
    );
    watch(
      () => form.fillable_id,
      () => {
        if (form.fillable_id) {
          form.entity_name = entitiesOptions.value.find(
              (entity) => {
                return form.fillable_type === 'test'
                    ? entity.id === form.fillable_id
                    : entity.id === parseInt(form.fillable_id);
              }
          ).text;
        }
      }
    );

    watch(() => profile.value, () => {
      if (profile.value && profile.value.role && !getRight('relations')) {
          router.push('/');
      }
    });

    return {
      form,
      submitRelations,
      siteProducts,
      sitePackets,
      productsOptions,
      entitiesCourses,
      entitiesTests,
      courseLink,
      entitiesOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.relations-form {
  padding: 0 0 10px 0;
  display: flex;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  &__course-link {
    margin-right: 20px;
  }

  &__col {
    padding: 10px;
    // flex-grow: 0;
  }
  .form-group {
    max-width: 100%;
    width: 100%;
    // max-width: 660px;
  }

  &__btns {
    padding-right: 20px;
  }

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 1500px) {
    &__inputs {
      flex-direction: column;
    }
    &__col {
      flex: 0 0 100% !important;
    }
  }

  @media (max-width: 973px) {
    .form-group {
      margin: 15px -15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    .form-group {
      margin: 15px -15px;
    }
  }

  @media (max-width: 425px) {
    &__organization {
      padding: 20px 20px 0;
    }

    .form-group {
      margin: 15px -15px;
    }
  }
}
</style>