<template>
  <div class="table-responsive relations-table">
    <table
      v-if="entityId && currentRelations.length"
      class="table table-striped"
    >
      <thead>
        <th style="min-width: 220px">Сущность на сайте</th>
        <th style="width: 220px">Тип сущности в ЛК</th>
        <th style="min-width: 220px">Сущность в ЛК</th>
        <th style="min-width: 220px">Название</th>
        <th style="width: 240px">Количество</th>
        <th style="width: 240px">Срок действия</th>
      </thead>
      <tbody>
        <tr v-for="relation of currentRelations" :key="relation.id">
          <td>{{ relation.site_entity_name }}</td>
          <td>{{ relation.fillable_type == "course" ? "Курс" : "Тест" }}</td>
          <td>{{ relation.fillable_name }}</td>
          <td>{{ relation.entity_name }}</td>
          <td
          >
            <div class="flex">
              <div v-if="editCountId == relation.id" class="editing_block">
                <custom-input
                    v-model="editCount"
                    type="number"
                    name="count"
                    :min="0"
                    :inputWidth="100"
                />
                <button class="edit-btn" @click="updateRelation(relation)">
                  <i class="fal fa-check"></i>
                </button>
                <button class="edit-btn" @click="editCountId = null">
                  <img src="@/assets/images/icons/close.svg" alt="" />
                </button>
              </div>
              <div v-else>
                {{ relation.count }}
              </div>
              <button
                  v-if="editCountId !== relation.id"
                  @click="openEditingCount(relation.id, relation.count)"
                  class="flex justify-content-between edit-btn"
              >
                <i class="fal fa-pen"></i>
              </button>
            </div>
          </td>
          <td
          >
            <div v-if="relation.fillable_type === 'course'" class="flex">
              <div v-if="editDurationId === relation.id" class="editing_block">
                <custom-input
                    v-model="editDuration"
                    type="number"
                    name="count"
                    :min="0"
                    :inputWidth="100"
                />
                <button class="edit-btn" @click="updateDuration(relation)">
                  <i class="fal fa-check"></i>
                </button>
                <button class="edit-btn" @click="editDurationId = null">
                  <img src="@/assets/images/icons/close.svg" alt="" />
                </button>
              </div>
              <div v-else>
                {{ relation.access_duration }}
              </div>
              <button
                  v-if="editDurationId !== relation.id"
                  @click="openEditingDuration(relation.id, relation.access_duration)"
                  class="flex justify-content-between edit-btn"
              >
                <i class="fal fa-pen"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, toRef } from "@vue/runtime-core";
import { useStore } from "vuex";
import CustomInput from "../../components/Forms/Fields/CustomInput.vue";
import { notify } from "@kyvg/vue3-notification";
// import Preloader from '../Technical/Preloader.vue';
export default {
  components: { CustomInput },
  //   components: { Preloader },
  name: "relations-table",
  props: {
    entityId: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore(),
        id = toRef(props, "entityId"),
        editDurationId = ref(null),
        editDuration = ref(null),
        editCountId = ref(null),
        editCount = ref(null);
    const relations = computed(() => store.state.relations.relations),
      dictionaryEntities = computed(
        () => store.state.relations.dictionaryEntities
      ),
      dictionaryProducts = computed(
        () => store.state.relations.dictionaryProducts
      ),
      currentRelations = computed(() =>
        relations.value
          .filter((rel) => rel.site_entity_id == id.value)
          .map((rel) => {
            const additionalRel = rel;
            additionalRel["site_entity_name"] =
              dictionaryProducts.value[
                `${rel.site_entity_type}_${rel.site_entity_id}`
              ];
            additionalRel["fillable_name"] =
              dictionaryEntities.value[rel.fillable_id];
            return additionalRel;
          })
      );
    onBeforeMount(() => {
      store.dispatch("relations/getRelations");
    });

    const openEditingCount = (id, count) => {
      editCountId.value = id;
      editCount.value = count;
    };

    const openEditingDuration = (id, duration) => {
      editDurationId.value = id;
      editDuration.value = duration;
    };

    const updateRelation = (relation) => {
      if (!editCount.value) {
        notify({
          type: "error",
          title: "Поле должно быть заполнено",
        });
      } else {
        const data = relation;
        data.count = editCount.value;
        store
          .dispatch("relations/updateRelation", data)
          .then(() => (editCountId.value = null));
      }
    };

    const updateDuration = (relation) => {
        const data = relation;
        data.access_duration = editDuration.value ?? null;
        store
            .dispatch("relations/updateRelation", data)
            .then(() => (editDurationId.value = null));
    };

    return {
      id,
      relations,
      currentRelations,
      editCountId,
      dictionaryEntities,
      dictionaryProducts,
      editCount,
      updateRelation,
      openEditingCount,
      openEditingDuration,
      editDuration,
      editDurationId,
      updateDuration
    };
  },
};
</script>

<style scoped lang="scss">
.count-editing {
  min-width: 190px;
  .form-group {
    justify-content: flex-start;
    max-width: 130px;
    margin: 0;
  }
}
.relations-table {
  tr {
    height: 76px;
  }
}

.editing_block {
  display: flex;
  justify-content: space-between;
  width: 160px;
}

</style>